/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image, Divider, Button, Slideshow } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"muzeum-meteoritu.cz - Podpora nového muzea"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-1tmab3o js-anim  --anim3 --anim-s3 --center" style={{"paddingTop":158,"marginBottom":0,"paddingBottom":302}} anim={"3"} name={"uvod"} animS={"3"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37955/0b413c99fb40498d86a68e5805495a74_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" style={{"maxWidth":1200}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":900}} anim={"2"} animS={"3"}>
              
              <Title className="title-box title-box--center fs--62 w--900" style={{"maxWidth":1107}} content={"<br>Podpoř unikátní Muzeum.<br>Nové a ještě lepší...&nbsp;<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"sluzby-2"} layout={"l30"}>
        </Column>


        <Column className="js-anim  --anim3 --anim-s3 pt--60" style={{"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"5srbmuzxmeh"} animS={"3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim3 --anim-s2 --center el--2 flex--center" style={{"backgroundColor":"var(--black)"}} columns={"2"} anim={"3"} animS={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Proč podpořit Muzeum meteoritů?</span>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":657}} content={"<span style=\"font-weight: bold;\">Jmenuji se Jaroslav FILIP a jsem vesmírný nadšenec.\n\n<br><br>Před mnoha lety jsem se pustil do sbírání vesmírných kamenů, tzv. meteoritů a tektitů, které mě absolutně pohltily. Unikátní sbírku, která dnes obsahuje několik set kusů, jsem před 2,5 lety zpřístupnil veřejnosti zcela ZDARMA.<br><br>Muzeum meteoritů už tak vlastně máme a funguje ve Frýdku-Místku. Jedná se o největší veřejně přístupnou, soukromou sbírku meteoritů a tektitů v Česku a na Slovensku.<br><br>&nbsp;Vzácné kameny, které přiletěly po milionech až miliardách let z vesmíru, jsou v muzeu nejen k vidění, ale některé také k potěžkání.\n<br><br>&nbsp;Při příležitosti blížícího se 3. výročí, jsme představili plán na nové Muzeum meteoritů. <br>Máme před sebou novou a nejtěžší výzvu.<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/f19ca64d93904c50a301ac248e671a2b_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/f19ca64d93904c50a301ac248e671a2b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/f19ca64d93904c50a301ac248e671a2b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/f19ca64d93904c50a301ac248e671a2b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/f19ca64d93904c50a301ac248e671a2b_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"backgroundColor":"var(--black)"}} columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/79fbcafb7fe8486e8099974f04db668f_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/79fbcafb7fe8486e8099974f04db668f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/79fbcafb7fe8486e8099974f04db668f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/79fbcafb7fe8486e8099974f04db668f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/79fbcafb7fe8486e8099974f04db668f_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/79fbcafb7fe8486e8099974f04db668f_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Proč nové Muzeum?</span>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":1000}} content={"<span style=\"font-weight: bold;\">Jednoduše potřebujeme více místa pro prezentaci sbírky, kterou neustále rozšiřujeme a také další místo pro naše nové plány.<br><br>&nbsp;Chceme vytvořit interaktivní prostor plný aktivit, vzdělávání a zábavy, kde se budou potkávat nejen fanoušci vesmíru a samotných meteoritů.<br><br>Nabídneme zajímavé přednášky, promítaní dokumentů, vzdělávání a akce pro všechny věkové skupiny.&nbsp;<br><br>&nbsp;Výhledově bychom se chtěli stát největším muzeum meteoritů ve střední Evropě.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"gt3ooi78dvb"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Divider >
              </Divider>

              <Title className="title-box fs--26" content={"Nezastavujeme...<br>Co bude s unikátním muzeem meteoritů dál? <br>Projekt na Donio.cz ukončen"}>
              </Title>

              <Button className="btn-box" content={"Číst článek"} use={"page"} href={"/projekt-na-doniocz-ukoncen"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3" style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"2e9nbjistlt"} animS={"3"}>
          
          <ColumnWrap className="column__flex js-anim  --anim5 --anim-s5 --center el--2 flex--center" columns={"2"} anim={"5"} animS={"5"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Na&nbsp; co&nbsp; vybrané&nbsp; peníze&nbsp; použijeme ?</span>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":669}} content={"<span style=\"font-weight: bold;\">Nejvíce potřebujeme více místa a multimediální techniku. <br>V tuto chvíli vybíráme vhodné prostory a jednáme jak s městem Frýdek-Místek, tak s firmami z blízkého i širokého okolí o podpoře našemu projektu.<br><br>&nbsp;Vaše příspěvky nám pomohou přiblížit se našemu snu. Jsme malý, ale skvělý a nadšený tým lidí, který má chuť dokázat velké věci.<br><br>Náklady na takto velký projekt jsou ovšem mimo naše finanční možnosti, proto si Vás dovolujeme zdvořile požádat o podporu.&nbsp;<br><br>Každá věnovaná koruna nám pomůže přiblížit se našemu snu.<br><br>DĚKUJEME !<br></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":631}} alt={"Takhle nějak bychom si představovali projekční část Muzea"} src={"https://cdn.swbpg.com/t/37955/928360e2db204cdbb50e461f86dd1a40_s=660x_.jpeg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/928360e2db204cdbb50e461f86dd1a40_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/37955/928360e2db204cdbb50e461f86dd1a40_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/37955/928360e2db204cdbb50e461f86dd1a40_s=860x_.jpeg 860w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/167bd87137dc4128bf3448fdea55a371_s=860x_.jpg"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/37955/167bd87137dc4128bf3448fdea55a371_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/167bd87137dc4128bf3448fdea55a371_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/167bd87137dc4128bf3448fdea55a371_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/167bd87137dc4128bf3448fdea55a371_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/167bd87137dc4128bf3448fdea55a371_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Naše vize</span>"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":655}} content={"<span style=\"font-weight: bold;\">Nové&nbsp;muzeum by podle našich ideálních představ mělo několik sekcí:<br><br>- samotné muzeum meteoritů, které budeme neustále doplňovat o nové exponáty<br>- moderní projekční sál pro přednášky, akce a dokumenty<br>- galerie plná fotografií vesmíru, vesmírných jevů a úkazů<br>- knihovna s tématikou vesmíru<br>- dětská zóna plná vzdělávání zábavnou formou<br>- relaxační část s příjemnou hudbou a pohodlnou odpočinkovou zónou<br>- vesmírná kavárna, jako doplněk knihy, nebo dokumentu<br>- náš vesmírný obchod s originálními kameny, nebo šperky&nbsp;<br><br>Celý prostor by pak byl plný interaktivních prvků, zajímavostí, informací a faktů.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--0 pt--50" name={"vgw62eqb9wk"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(144, 19, 254); font-weight: 700;\">Děkujeme za podporu!</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow name={"hqs7qxgxgw"} autoplay={true} infinite={true} transitionDuration={3}>
          
          <Column className="pb--60 pt--60">
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Title className="title-box" content={"<span style=\"font-weight: bold;\">&nbsp;Meteority.com</span>"}>
                </Title>

                <Text className="text-box" style={{"maxWidth":650}} content={"<span style=\"font-weight: bold;\">Tvůj kousek vesmíru a ten nejlepší obchod s kameny z vesmíru, nebo originální šperky. Partner našeho muzea. Děkujeme za podporu!<br><a href=\"https://www.meteority.com/\">www.meteority.com&nbsp;</a><br></span>"}>
                </Text>

                <Image style={{"maxWidth":417}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/52e562a5e1584ff782de6fd3d25e30e4_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/52e562a5e1584ff782de6fd3d25e30e4_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/52e562a5e1584ff782de6fd3d25e30e4_s=860x_.png 860w, https://cdn.swbpg.com/t/37955/52e562a5e1584ff782de6fd3d25e30e4_s=1400x_.png 1400w, https://cdn.swbpg.com/t/37955/52e562a5e1584ff782de6fd3d25e30e4_s=2000x_.png 2000w"} alt={""} src={"https://cdn.swbpg.com/t/37955/52e562a5e1584ff782de6fd3d25e30e4_s=860x_.png"}>
                </Image>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Title className="title-box" content={"<span style=\"font-weight: bold;\">&nbsp;Město Frýdek-Místek</span>"}>
                </Title>

                <Image style={{"maxWidth":246}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=350x_.png 350w, https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=660x_.png 660w, https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/37955/d8d5a20f0bda46fbbcb3d2ac8dbdaebf_s=660x_.png"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="--center pt--80" name={"n5xipmb0a1"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"xro39m85vz"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box fs--30" content={"<span style=\"font-weight: bold; color: var(--color-custom-1);\">Každá koruna nám pomáhá...</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":603}} content={"<span style=\"font-weight: bold;\">Pro příspěvek přímo na náš bankovní účet můžeš použít následující údaje:&nbsp;<br>Muzeum meteoritů s.r.o.&nbsp;<br>č. účtu:&nbsp;888777970/5500<br>Pro platby v CZK můžeš použít také tento QR kód:<br></span>"}>
              </Text>

              <Image style={{"maxWidth":241}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/37955/968f7b2ec16e4a4cb43ea115df4ea2d6_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/37955/968f7b2ec16e4a4cb43ea115df4ea2d6_s=350x_.png"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold;\">Pro platby v € prosím použij následující platební údaje:<br>IBAN:  CZ2055000000000888777970\n<br>SWIFT:  RZBCCZPP<br><br>V každém případě nám dej vědět, zda nás podpoříš jen tak, nebo sis vybral nějakou odměnu.<br>Můžeš to zadat do poznámky k platbě spolu s kontaktem, nebo nám napiš na mail info@muzeum-meteoritu.cz a domluvíme podrobnosti.<br><br>ZA KAŽDOU PODPORU VELICE DĚKUJEME A MOC SI JI VÁŽÍME !<br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3" style={{"paddingTop":55,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}} anim={"3"} name={"sq71x39thfc"} animS={"3"} layout={"l29"} lightbox={false}>
          
          <ColumnWrap className="column__flex --center">
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":1000}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/2139c286973843eb82852912b8247fbd_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":620}} alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/687673f8cd644519956211b27876955c_s=860x_.jpg 860w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/37955/d885e40ef67149f483de7cfbb9bbd95e_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=860x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=350x_.jpg 350w, https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=660x_.jpg 660w, https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=860x_.jpg 860w, https://cdn.swbpg.com/t/37955/62710af38527434f872a0e73a26e3887_s=1400x_.jpg 1400w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="js-anim  --anim3 --anim-s3 --center" style={{"paddingTop":63,"paddingBottom":2}} anim={"3"} name={"ekd4moa2gds"} animS={"3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--20" content={"Muzeum Meteoritů s.r.o.&nbsp; © 2024  Vytvořeno s ❤️ k vesmíru"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}